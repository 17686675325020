import confetti from 'canvas-confetti'
import React, { useEffect, useState, useRef } from 'react'
import { useTheme } from '@skagami/gatsby-plugin-dark-mode'
import useWindowDimensions from '../utils/use-window-dimensions';

function Accordion() {

	function showConfetti(theme) {
		function randomNumber(min, max) { 
			return Math.random() * (max - min) + min;
		}

		function randomElement(items) {
			return items[Math.floor(Math.random()*items.length)];
		}

		var colors = [];

		switch (theme) {
			case "seventies":
				colors = ["#CE494A", "#E8AE40", "#47A569", "#6689C3", "#B35DA6"];
				break;

			case "eighties":
				colors = ["#FF48C4", "#2BD1FC", "#F3EA5F", "#C04DF9", "#FF3F3F"];
				break;

			case "nineties":
				colors = ["#00DB96", "#49297E", "#E10086", "#FF6700", "#58BDC4"];
				break;

			default:
				break;
		}
		
		const isDesktop = width > 768
		const spread = isDesktop ? randomNumber(200, 400) : randomNumber(100, 200)
		const startVelocity = isDesktop ? 75 : 45 // 45 is the library's default
		const decay = isDesktop ? 0.98 : 0.97
		const origin = isDesktop ?  { x: 0.1, y: 0.95 } : { x: randomNumber(0.35, 0.65), y: randomNumber(0.5, 0.65) }
		const angle = isDesktop ? randomNumber(42, 48) : randomNumber(75, 105)
		const scalar = isDesktop ? randomNumber(1.0, 5.0) : randomNumber(1.0, 2.0)

		confetti({
	  	particleCount: randomNumber(200, 400),
	  	spread: spread,
			angle: angle,
			startVelocity: startVelocity,
			decay: decay,
	  	shapes: randomElement([["square"], ["circle"], ["circle"], ["square", "circle"]]),
			origin: origin,
			colors: colors,
	  	scalar: scalar,
	  	gravity: randomNumber(0.8, 1.25),
	  	drift: randomNumber(-0.2, 0.2),
	  	disableForReducedMotion: true,
		});
	}

	function activeTitle(theme) {
		if (theme == null) {
			return "​					           				                    " // Empty spaces with a zero-width spacer for sizing purposes
		} else {
			return "You're In " + modeText(theme)	
		}
	}

	function inactiveTitle(activeTheme, comparisonTheme) {
		return "Enter " + modeText(comparisonTheme)
	}

	function modeText(theme) {
		switch (theme) {
			case "seventies":
				return "70's Mode";
		
			case "eighties":
				return "80's Mode";
		
			case "nineties":
				return "90's Mode";
		
			default:
				break;
		}
	}

	const { width } = useWindowDimensions();
	const [setActive, setActiveState] = useState("");
	const [setHeight, setHeightState] = useState("0px");
	const [theme, toggleTheme] = useTheme()

	useEffect(()=>{
		if (theme === "dark" || theme === "light") {
			toggleTheme("seventies");
		}
	}, [])

	function setCurrentTheme(theme) {
		toggleTheme(theme)
		showConfetti(theme)
		toggleAccordion()
	}

	const content = useRef(null);

	function toggleAccordion() {
		setActiveState(setActive === "" ? "active" : "");
		setHeightState(
			setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
		);
	}

// className={`${theme == null ? "transparent-text" : "" }`}>{

	return(
		<div className="accordion-container">
			<button className={`box-button accordion-box-button accordion ${setActive}`} onClick={toggleAccordion}>
				<div>{activeTitle(theme)}</div>
			</button>
				<div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion-items">
					<div className="box-button accordion-box-button seventies-toggle" onClick={() => { setCurrentTheme("seventies") }}>{inactiveTitle(theme, "seventies")}</div>

					<div className="box-button accordion-box-button eighties-toggle" onClick={() => { setCurrentTheme("eighties") }}>{inactiveTitle(theme, "eighties")}</div>

					<div className="box-button accordion-box-button nineties-toggle" onClick={() => { setCurrentTheme("nineties")}}>{inactiveTitle(theme, "nineties")}</div>
				</div>	
			</div>
		);
}

export default Accordion
