import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

function Contact() {

	return(
		<StaticQuery
			query={graphql`
				query SiteMetadataQuery {
				  site {
						siteMetadata {
							social {
								twitter
								threads
								linkedin
								github
								email
								rss
							}
						}
				  }
				}
			`}
			render={data => (
				<section className="contact">            
				  <ul>            
						{data.site.siteMetadata.social.threads && (
						  <li alt="Threads">
						  	<a rel="me" href={`https://threads.net/${data.site.siteMetadata.social.threads}`} target="_blank">
						  	  <i className="fa fa-yarn" aria-hidden="true" />
							</a>
						  </li>
						)}
						{/* Mastodon is unimplemented, I might add it at some point in the future */}
						{data.site.siteMetadata.social.mastodon && (
						  <li alt="Mastodon">
							  <a rel="me" href={`https://macaw.social/${data.site.siteMetadata.social.mastodon}`} target="_blank">
							  <i className="fa fa-mastodon" aria-hidden="true" />
							</a>
						  </li>
						)}
						{data.site.siteMetadata.social.github && (
					  	<li alt="GitHub">
							<a href={`https://github.com/${data.site.siteMetadata.social.github}`} target="_blank">
						  	<i className="fa fa-github" aria-hidden="true" />
							</a>
					  	</li>
						)}
						{data.site.siteMetadata.social.linkedin && (
					  	<li alt="LinkedIn">
							<a href={`https://linkedin.com/in/${data.site.siteMetadata.social.linkedin}`} target="_blank">
						  	<i className="fa fa-linkedin" aria-hidden="true" />
							</a>
					  	</li>
						)}
						{data.site.siteMetadata.social.email && (
					  	<li alt="Email">
							<a href={`mailto:${data.site.siteMetadata.social.email}`} target="_blank">
						  	<i className="fa fa-envelope-o" aria-hidden="true" />
							</a>
					  	</li>
						)}
						{data.site.siteMetadata.social.twitter && (
						  <li alt="Twitter">
							<a href={`https://threads.net/${data.site.siteMetadata.social.twitter}`} target="_blank">
							  <i className="fa fa-twitter" aria-hidden="true" />
							</a>
						  </li>
						)}						
						{data.site.siteMetadata.social.rss && (
						<li alt="RSS">
						<a href={`${data.site.siteMetadata.social.rss}`} target="_blank">
							<i className="fa fa-rss" aria-hidden="true" style={{textRendering: "optimizeLegibility"}} />
						</a>
						</li>
						)}
				  </ul>
				</section>
			)}
		/>
  );
}

export default Contact