import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Sidebar from '.././components/sidebar'
import '../styles/main.scss'
import '../styles/fonts/font-awesome/css/font-awesome.min.css'

function DefaultLayout({children}) {
  return(  
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              author
              description
            }
          }
        }
      `}
      render={data => (
        <div className="wrapper">
          <Sidebar siteMetadata={data.site.siteMetadata} />
          {children}
            <a rel="me" href="https://threads.net/@mergesort"></a>
            <a rel="me" href="https://macaw.social/@mergesort"></a>
		        <a rel="me" href="https://indieapps.space/@redpandaclub"></a>
        </div>
      )}
  />
);
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
