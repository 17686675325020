import React from 'react'
import { Link } from 'gatsby'
import Logo from './pic.jpg'
import Contact from "../components/contact"
import Accordion from "../components/accordion"

function Sidebar({siteMetadata}) {
    return(
      <div>
        <aside className="sidebar">
          <div className="about">
            <div className="cover-author-image">
              <Link to="/">
                <img src={Logo} alt={siteMetadata.author} />
              </Link>
            </div>
            <Link to="/">
              <div className="author-name box-button white-shadow">{siteMetadata.author}</div>
            </Link>
            <Contact/>
            <p>
              An indie developer building <Link to="https://redpanda.club" className="red-link">Red Panda Club Inc.</Link> Writing personally here and teaching people about engineering, design, business, and more <Link to="https://build.ms" className="purple-link">@build.ms</Link>. Formerly an iOS developer working on civic integrity, societal health, misinformation, and other wild subjects <Link to="https://threads.net/@mergesort" className="blue-link">@Twitter</Link>.
            </p>
          </div>
  
        <section className="links">
          <Link to="/about" activeClassName={"quatenary-background"}><button className="box-button quatenary-shadow">About</button></Link>
        
          <Link to="/tags/featured" activeClassName={"secondary-background"}><button className="box-button secondary-shadow">Top Posts</button></Link>
        
          <Link to="/reading-list" activeClassName={"tertiary-background"}><button className={"box-button tertiary-shadow"}>Reading List</button></Link>
        
          <Link to="/others-writing" activeClassName={"accent-background"}><button className="box-button accent-shadow">Others' Writing</button></Link>
          
          <Link to="/nice-people" activeClassName={"primary-background"}>
          <button className="box-button primary-shadow">Some Nice People</button></Link>
        </section>

        <Accordion />

      </aside>
    </div>
  );
}

export default Sidebar
